<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" @click="onPressNewClient('contactData')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
      <el-col :span="20" :offset="4">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Харилцагч үүсгэх'"/>
        <el-form label-position="top" :model="contactData" :rules="clientRules" ref="contactData">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Нэр:" prop="name">
                      <el-input v-model="contactData.name" placeholder="Харилцагчийн нэр"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Ажлын хаяг:" prop="address">
                      <el-input v-model="contactData.address" placeholder="ХУД-3р хороо"></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Е-мэйл хаяг:" prop="email">
                        <el-input v-model="contactData.email" placeholder="hrm@gmail.com"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Утасны дугаар:" prop="phone">
                      <el-input v-model="contactData.phone" type="number" placeholder="77667766"></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Албан тушаал:" prop="major">
                        <el-input v-model="contactData.major" placeholder="Борлуулалтын менежер ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Удирдах нийлүүлэгчид" prop="supplier_id">
                      <el-select v-model="contactData.supplier_id" filterable placeholder="Select" style="width: 98%">
                        <el-option
                          v-for="(item, index) in suppliers"
                          :key="index"
                          :label="item.supplier_monName"
                          :value="item.supplier_id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
            <el-row :gutter="20">
              <el-col :span="16" class="text-right">
               <div class="border-top pt20">
                  <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                  <el-button type="success" @click="onPressNewClient('contactData', 'clientRules')"
                  >Хадгалах</el-button>
               </div>
              </el-col>
            </el-row>
        </el-form>
      </el-col>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/client-contact-list">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
export default {
  components: {
    customHeader: customHeader
  },
  created () {
    this.getSupplier()
  },
  data () {
    return {
      confirmLeaveForm: false,
      loading: false,
      suppliers: [],
      contactData: {
        name: '',
        major: '',
        phone: '',
        email: '',
        address: '',
        supplier_id: ''
      },
      clientRules: {
        name: [
          { required: true, message: 'нэрээ оруулна уу', trigger: 'blur' }
        ],
        major: [
          { required: true, message: 'Албан тушаалаа оруулна уу', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Утас дугаараа оруулна уу', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Е-мэйл хаягаа оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Ажлын хаягаа оруулна уу', trigger: 'blur' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    async onPressNewClient (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const body = this.contactData
          if (this.contactData.employee_id !== '') {
            services.createClientEmployees(body).then(data => {
              this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
              if (data.status === 'success') {
                this.$refs[formName].resetFields()
                this.loading = false
                this.$router.push({ name: 'clientContactList' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              } else {
                return false
              }
            })
          }
        } else {
          console.log('error')
        }
      })
    },
    getSupplier () {
      this.isLoading = true
      services.getSuppliers('?size=2000').then(response => {
        if (response.data && Array.isArray(response.data)) {
          if (response.data.length === 1) {
            this.contactData.supplier_id = response.data[0].supplier_id
            this.suppliers = response.data
            this.isLoading = false
          } else {
            this.suppliers = response.data
            this.isLoading = false
          }
        } else {
          this.suppliers = []
          this.isLoading = false
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    }
  }

}
</script>

<style></style>
